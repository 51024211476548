import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import { useSelector } from "react-redux";
import FullPageLoader from "../components/loaders/FullPageLoader";
// import { HomeView } from "../sections/home/";

const IndexPage = lazy(() => import("../pages/home"));
const HomePage = lazy(() => import("../pages/app"));
const BusinessesPage = lazy(() => import("../pages/businesses"));
const RequestsPage = lazy(() => import("../pages/requests"));
const LoginPage = lazy(() => import("../pages/auth/login"));
const SignupPage = lazy(() => import("../pages/auth/signup"));
const OtpPage = lazy(() => import("../pages/auth/otp"));
const QrCodePage = lazy(() => import("../pages/qr-code"));
const PaymentPage = lazy(() => import("../pages/payment"));
const PaymentPlansPage = lazy(() => import("../pages/payment-plans"));
const ForgotPasswordPage = lazy(() => import("../pages/auth/forgot-password"));
const NewPasswordPage = lazy(() => import("../pages/auth/new-password"));
const Page404 = lazy(() => import("../pages/page-not-found"));
// const BarTv = lazy(() => import("../sections/auth/home/barTv"));
const BarTv = lazy(() => import("../pages/tvScreen"));
const BarTvView = lazy(() => import("../pages/bar-tv"));
const CustomerMessage = lazy(() => import("../pages/customerMessage"));

export default function Router() {
  const { user } = useSelector((state) => state.user);
  const adminRoutes = [
    { index: true, element: <IndexPage /> },
    { path: "home", element: <HomePage /> },
    { path: "businesses", element: <BusinessesPage /> },
  ];

  const businessRoutes = [
    { index: true, element: <IndexPage /> },
    { path: "home", element: <HomePage /> },
    { path: "qr-code", element: <QrCodePage /> },
    { path: "payment", element: <PaymentPage /> },
    { path: "payment", element: <PaymentPage /> },
    { path: "requests", element: <RequestsPage /> },
    { path: "payment-plans", element: <PaymentPlansPage /> },
    { path: "tv-screen", element: <BarTvView /> },

  ];



  const routes = useRoutes([
    {
      path: "/",
      element:
        user === null ? (
          // <Navigate to="/" replace />
          <IndexPage />
        ) : (
          <DashboardLayout>
            <Suspense fallback={<FullPageLoader isLoading={true}/>}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        ),
      children: [
        ...(user && user.role === "admin" ? adminRoutes : businessRoutes),
      ],
    },
    {
      path: "/login",
      element: user === null ? <LoginPage /> : <Navigate to="/" replace />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
    // {
    //   path: "/",
    //   element: <IndexPage />,
    // },

    // {
    //   path: "/",
    //   element: <HomePage />,
    // },
    {
      path: "/otp",
      element: <OtpPage />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/new-password",
      element: <NewPasswordPage />,
    },
    { path: "customerMessage/:id", element: <CustomerMessage /> },
    { path: "tvScreen/:id", element: <BarTv /> },
    {
      path: "/404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
