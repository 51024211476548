import axios from "./axios";

const HOST_API = process.env.REACT_APP_HOST_API;
// const HOST_API ='http://localhost:3002/api/v1';
export const postRequest = async ({ endpoint, payload }) => {
  let response = await axios.post(`${HOST_API}${endpoint}`, payload);
  return response;
};

export const getRequest = async ({ endpoint }) => {
  let response = await axios.get(`${HOST_API}${endpoint}`);
  return response;
};

export const putRequest = async ({ endpoint, payload }) => {
  let response = await axios.put(`${HOST_API}${endpoint}`, payload);
  return response;
};

export const patchRequest = async ({ endpoint, payload }) => {
  let response = await axios.patch(`${HOST_API}${endpoint}`, payload);
  return response;
};

export const deleteRequest = async ({ endpoint }) => {
  let response = await axios.delete(`${HOST_API}${endpoint}`);
  return response;
};
