import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../../utils/axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/request";

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAllUser(state, action) {
      state.users = action.payload;
    },

    setNewUser(state, action) {
      state.users = state.users.concat(action.payload);
    },

    setDeleteUser(state, action) {
      state.users = state.users.filter((user) => user.id !== action.payload.id);
    },

    setUpdateUser(state, action) {
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },

    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

export const loginUser = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: `${API_ENDPOINTS.auth.login}`,
      payload: payload,
    });
    await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
    // throw new Error(error.response.data.message);
  }
};

export const getAllUser = (id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.auth.getAllUser}/${id}`,
    });
    dispatch(actions.setAllUser(response.data.data));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};

export const addNewUser = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: API_ENDPOINTS.auth.addUser,
      payload: payload,
    });
    dispatch(actions.setNewUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await deleteRequest({
      endpoint: `${API_ENDPOINTS.auth.deleteUser}/${id}`,
    });
    dispatch(actions.setDeleteUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const updateUser = (id, payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.auth.updateUser}/${id}`,
      payload: payload,
    });
    dispatch(actions.setUpdateUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.auth.getUser}/${id}`,
    });
    dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};

export const logout = () => async (dispatch) => {
  await dispatch(actions.setUser(null));
};

export const signupUser = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: `${API_ENDPOINTS.auth.signup}`,
      payload: payload,
    });
    // await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const verifyOtp = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: `${API_ENDPOINTS.auth.verifyOtp}`,
      payload: payload,
    });
    // await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const resendOtp = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.auth.resendOtp}`,
      payload: payload,
    });
    console.log("res==========", response);
    // await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
    // return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    // return error;
    throw error;
  }
};

export const forgotPassword = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.auth.forgotPassword}`,
      payload: payload,
    });
    console.log("res==========", response);
    // await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    // return error;
    throw error;
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.auth.resetPassword}`,
      payload: payload,
    });
    console.log("res==========", response);
    // await dispatch(actions.setUser(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    // return error;
    throw error;
  }
};
