import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../../utils/axios";
import { getRequest, putRequest } from "../../utils/request";

const initialState = {
  isLoading: false,
  error: null,
  businesses: [],
  totalBusinesses: null,
};

const slice = createSlice({
  name: "business",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setTotalBusinesses(state, action) {
      state.totalBusinesses = action.payload;
    },

    setBusinesses(state, action) {
      state.businesses = action.payload;
    },
    updateBusinessUser(state, action) {
      state.businesses = state.businesses.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    setNewBusiness(state, action) {
      state.businesses = state.businesses.concat(action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

export const getAllBusiness = (perPage, page) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.business.getAllBusiness}?perPage=${perPage}&page=${page}`,
    });
    dispatch(actions.setBusinesses(response.data.data.businesses));
    dispatch(actions.setTotalBusinesses(response.data.data.total));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};
export const updateBusiness = (id,payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.business.updateBusiness}/${id}`,payload
    });
    dispatch(actions.updateBusinessUser(response.data.data));
    console.log(response.data.data);
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};
