import axios from "axios";
import { userConstants } from "../constants/user";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers[userConstants.tokenVariable] = localStorage.getItem(
      userConstants.tokenVariable
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    const token = response?.headers[userConstants.tokenVariable];

    let publicRoutes=['customerMessage','tvScreen'];
    let path=window.location.pathname.split('/')[1];
    if (token && !publicRoutes.includes(path)) localStorage.setItem(userConstants.tokenVariable, token);
    return response;
  },
  function (error) {
    if (error?.response?.data?.statusCode === 401) {
      localStorage.removeItem(userConstants.tokenVariable);
      localStorage.removeItem("persist:root");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default axios;

export const API_ENDPOINTS = {
  auth: {
    getAllUser: "/users",
    getUser: "users",
    me: "/users/me",
    login: "/users/login",
    signup: "/users",
    verifyOtp: "/users/verifyOtp",
    resendOtp: "/users/resendOtp",
    forgotPassword: "/users/forgotPassword",
    resetPassword: "/users/resetPassword",
    register: "/api/auth/register",
    addUser: "/users",
    deleteUser: "/users",
    updateUser: "/users",
  },
  business: {
    getAllBusiness: "/users/business/all",
    getBusinessDashboard: "/users/business/dashboard",
    getBusinessCharts: "/users/business/dashboard/charts",
    updateBusiness: "/users",
  },
  payment: {
    addPayment: "/payment",
    getPaymentMethods: "/payment/all/methods",
  },
  request: {
    request: "/requests",
    requestbyUser: "/requests/all",
    requestStats: "/requests/all/dashboard",
    requestCharts: "/requests/all/charts",
    requestNew: "/requests/all/new",
    addRequest: "/requests",
    deleteRequest: "/requests",
    updateRequest: "/requests",
    updateRequestStatus: "/requests/business",
    getApprovedRequest: "/requests/business",
    getAllRequest: "/requests/all",
    getAllRequestByBusiness: "/requests",
  },
  bank: {
    addBank: "/banks",
    getBank: "/banks",
    getAllVerifiedBanks: "/banks/all/verified",
    getBankForCustomer: "/banks/business"
  },
  paymentPlans: {
    // getAllPaymentPlans: "/payment/plans/all",
    updPaymentPlan: "/payment/plans",
    getAllPaymentPlansByBusiness: "/payment/plans/business",
    getAllPaymentPlansByBusinessForCustomer: "/payment/plans/all/business",
    createPaymentPlan: "/payment/plans",
    delPaymentPlan: "/payment/plans",
    getPaymentPlan: "/payment/plans",
  },
};
