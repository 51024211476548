import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../../utils/axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/request";

const initialState = {
  isLoading: false,
  error: null,
  requests: [],
  request:null,
  
  totalRequests: null,
};

const slice = createSlice({
  name: "request",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setTotalRequests(state, action) {
      state.totalRequests = action.payload;
    },

    setRequests(state, action) {
      state.requests = action.payload;
    },

    setNewRequest(state, action) {
      state.requests = state.requests.concat(action.payload);
    },

    setDeleteRequest(state, action) {
      state.requests = state.requests.filter((request) => request.id !== action.payload.id);
    },

    setUpdateRequest(state, action) {
      state.requests = state.requests.map((request) =>
      request.id === action.payload.id ? action.payload : request
      );
    },

    setRequest(state, action) {
      state.requests = action.payload;
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

export const getAllRequest = (perPage, page) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.request.getAllRequest}?perPage=${perPage}&page=${page}`,
    });
    dispatch(actions.setRequests(response.data.data.requests));
    dispatch(actions.setTotalRequests(response.data.data.total));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};

export const addNewRequest = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: API_ENDPOINTS.request.addRequest,
      payload: payload,
    });
    dispatch(actions.setNewRequest(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const deleteRequests = (id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await deleteRequest({
      endpoint: `${API_ENDPOINTS.request.deleteRequest}/${id}`,
    });
    dispatch(actions.setDeleteRequest(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const updateRequest = (id, payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.request.updateRequest}/${id}`,
      payload: payload,
    });
    dispatch(actions?.setUpdateRequest(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const getAllRequestByBusiness = (perPage, page) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.request.getAllRequestByBusiness}?perPage=${perPage}&page=${page}`,
    });
    dispatch(actions.setRequests(response.data.data.requests));
    dispatch(actions.setTotalRequests(response.data.data.total));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};
