import React from "react";
import { useSelector } from "react-redux";
import SvgColor from "../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

function useNavConfig() {
  const { user } = useSelector((state) => state.user);

  const navAdminConfig = [
    {
      title: "dashboard",
      path: "/home",
      icon: icon("ic_analytics"),
    },
    {
      title: "businesses",
      path: "/businesses",
      icon: icon("ic_user"),
    },
  ];

  const navBusinessConfig = [
    {
      title: "dashboard",
      path: "/home",
      icon: icon("ic_analytics"),
    },
    {
      title: "requests",
      path: "/requests",
      icon: icon("ic_user"),
    },
    {
      title: "Qr Code",
      path: "/qr-code",
      icon: icon("ic_user"),
    },
    {
      title: "Payment",
      path: "/payment",
      icon: icon("ic_user"),
    },
    {
      title: "Payment Plans",
      path: "/payment-plans",
      icon: icon("ic_user"),
    },
    {
      title: "Tv Screen",
      path: "/tv-screen",
      icon: icon("ic_user"),
    },
  ];

  return user && user.role === "admin" ? navAdminConfig : navBusinessConfig;
}

export default useNavConfig;
