import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../../utils/axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/request";

const initialState = {
  isLoading: false,
  error: null,
  paymentPlans: [],
  total:0
};

const slice = createSlice({
  name: "paymentplans",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setDeletePaymentPlan(state, action) {
      state.requests = state.filter((plan) => plan.id !== action.payload.id);
    },

    setPaymentPlans(state, action) {
      state.paymentPlans = action.payload;
    },
    setPaymentPlansTotal(state, action) {
      state.total = action.payload;
    },
    setNewPaymentPlan(state, action) {
      state.paymentPlans = state.paymentPlans.concat(action.payload);
    },

    setDeletePaymentPlan(state, action) {
      state.paymentPlans = state.users.filter(
        (request) => request.id !== action.payload.id
      );
    },

    setUpdatePaymentPlans(state, action) {
      state.paymentPlans = state.paymentPlans.map((request) =>
        request.id === action.payload.id ? action.payload : request
      );
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

export const getAllPaymentPlans = (perPage, page,id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.paymentPlans.getAllPaymentPlansByBusiness}/${id}?perPage=${perPage}&page=${page}`,
    });
    dispatch(actions.setPaymentPlans(response?.data?.data?.paymentPlans));
    dispatch(actions.setPaymentPlansTotal(response?.data?.data?.total));
    dispatch(actions.stopLoading());
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
  }
};

export const addNewPaymentPlan = (payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await postRequest({
      endpoint: API_ENDPOINTS.paymentPlans.createPaymentPlan,
      payload: payload,
    });
    dispatch(actions.setNewPaymentPlan(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const deletePaymentPlan = (id) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await deleteRequest({
      endpoint: `${API_ENDPOINTS.paymentPlans.delPaymentPlan}/${id}`,
    });
    // dispatch(actions.setDeletePaymentPlan(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const updatePaymentPlan = (id, payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await putRequest({
      endpoint: `${API_ENDPOINTS.paymentPlans.updPaymentPlan}/${id}`,
      payload: payload,
    });
    dispatch(actions.setUpdatePaymentPlans(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};

export const getPaymentPlan = (id, payload) => async (dispatch) => {
  try {
    dispatch(actions.startLoading());
    const response = await getRequest({
      endpoint: `${API_ENDPOINTS.paymentPlans.getPaymentPlan}/${id}`,
      payload: payload,
    });
    dispatch(actions.setPaymentPlans(response.data.data));
    dispatch(actions.stopLoading());
    return response;
  } catch (error) {
    dispatch(actions.stopLoading());
    dispatch(actions.hasError(error));
    throw error;
  }
};
