import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const styles = {
    backdrop: {
        zIndex: 9999,
        color: '#fff',
        backdropFilter: 'blur(8px)',
    },
};

export default function FullPageLoader({ isLoading }) {
    return (
        <Backdrop open={isLoading} style={styles.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

