import "./global.css";
import  "./App.css"

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Router from "./routes/sections";
import ThemeProvider from "./theme";
import {  ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  useScrollToTop();

  return (
    <ThemeProvider>
      <ToastContainer/>
      <Router />
    </ThemeProvider>
  );
}

export default App;
