import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import Nav from './nav';
import Main from './main';
import Header from './header';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const location = useLocation();

  return (
    <>
      {
        (location.pathname !== "/" && location.pathname !== "/barTv" && location.pathname !== "/customerMessage") && <Header onOpenNav={() => setOpenNav(true)} />
      }

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {
          (location.pathname !== "/" && location.pathname !== "/tvScreen" && location.pathname !== "/customerMessage") && <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />
        }

        {location.pathname === "/tvScreen" && <BarTvHeader />} {/* Add your BarTv header component */}
        {location.pathname === "/customerMessage" && <CustomerMessageHeader />} {/* Add your CustomerMessage header component */}
        <Main style={{paddingBottom:'0px'}}>{children}</Main>
      </Box>
    </>
  );
}

function BarTvHeader() {

}

function CustomerMessageHeader() {

}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
