import thunk from "redux-thunk";
import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session';
import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
} from "redux-persist";

import userReducer from "./slices/user";
import businessReducer from "./slices/business";
import requestReducer from "./slices/request";
import paymentPlansReducer from "./slices/paymentPlans"

const rootReducer = combineReducers({
  user: userReducer,
  business: businessReducer,
  request: requestReducer,
  paymentPlans: paymentPlansReducer,
});

const persistConfig = {
  key: "root",
  storage:sessionStorage,
  whitelist: ['user']
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk), // Add thunk middleware
});

export const persistor = persistStore(store);
